import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'
import BlockRounded from 'components/elements/Blocks/BlockRounded'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import NavLink from 'components/shared/NavLink'

// Images
import Arrow from 'img/arrow-rounded.inline.svg'

const Section = styled.section`
  color: ${({ theme }) => theme.color.primary};
  border-bottom: 1px solid #dbdfe5;
`

const Icon = styled(Plaatjie)<{ highlighted?: 'true' | 'false' }>`
  ${(props) =>
    props.highlighted === 'true'
      ? css`
          @media (min-width: 576px) {
            width: 210px;
            height: 190px;
          }

          @media (max-width: 575px) {
            width: 105px;
            height: 95px;
          }
        `
      : css`
          @media (min-width: 1440px) {
            width: 150px;
            height: 150px;
          }

          @media (max-width: 1439px) {
            width: 75px;
            height: 75px;
          }
        `}
`

const Col = styled(motion.div)`
  & h2 {
    @media (min-width: 1440px) {
      font-size: ${({ theme }) => theme.font.size.large};
    }
    @media (max-width: 1439px) {
      font-size: ${({ theme }) => theme.font.size.big};
    }
    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.medium};
    }
    @media (max-width: 575px) {
      font-size: ${({ theme }) => theme.font.size.small};
    }
  }
`

const ColNavLink = styled(NavLink)`
  font-size: 0;
`

const Background = styled(Plaatjie)`
  z-index: -1;
`

const ArrowWrapper = styled(motion.div)`
  bottom: 0;
  right: 20px;

  @media (max-width: 767px) {
    & > svg {
      width: 40px;
      height: 40px;
    }
  }

  @media (max-width: 575.98px) {
    right: -10px;
  }
`

interface ServicesProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Services
}

const Services = ({ fields }: ServicesProps) => {
  // eslint-disable-next-line no-unused-vars
  const [currentIndex, setCurrentIndex] = useState(0)
  const [hoverIndex, setHoverIndex] = useState(-1)

  return (
    <Section className="position-relative mb-5 pb-5 d-flex justify-content-center">
      <Background
        image={fields.background}
        className="position-absolute h-100"
        alt=""
      />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-9">
            <ParseContent content={fields.description} className="mb-5" />
            <div className="row justify-content-center">
              {fields.services?.map((service, index) => {
                const open = currentIndex === index
                const hovered = hoverIndex === index

                return (
                  <React.Fragment key={service?.title}>
                    {open && (
                      <>
                        <Col
                          className="col-12"
                          id={`highlighted-${index}`}
                          initial={{ opacity: 0 }}
                          animate={
                            open
                              ? {
                                  opacity: 1,
                                }
                              : { opacity: 0 }
                          }
                          transition={{ duration: 0.4 }}
                        >
                          <BlockRounded
                            light
                            className="px-sm-5 py-sm-4 py-3 mb-5"
                          >
                            <div className="d-flex flex-column flex-md-row align-items-center py-3">
                              <Icon
                                highlighted="true"
                                image={service?.icon}
                                alt=""
                                className="mb-4 mb-md-0"
                              />
                              <div className="px-sm-5 px-4">
                                <ParseContent
                                  content={service?.description}
                                  className="mb-3"
                                />
                                <ButtonPrimary to={service?.link?.url || '#'}>
                                  {service?.link?.title}
                                </ButtonPrimary>
                              </div>
                            </div>
                          </BlockRounded>
                        </Col>
                      </>
                    )}
                    {!open && (
                      <Col
                        className="col-6 col-sm-4 d-flex flex-column justify-content-center align-items-center order-1 h-100"
                        // onClick={() => handleHighlight(index)}
                        onHoverStart={() => setHoverIndex(index)}
                        onHoverEnd={() => setHoverIndex(-1)}
                        role="button"
                        tabIndex={-1}
                        // onKeyDown={() => setCurrentIndex(index)}
                      >
                        <ColNavLink to={service?.link?.url || '#'}>
                          <BlockRounded
                            light
                            className="position-relative h-100 d-flex justify-content-center align-items-center rounded-circle p-4 p-md-5 mb-2 mb-sm-0"
                          >
                            <ArrowWrapper
                              className="position-absolute"
                              initial={{ rotate: 0 }}
                              animate={hovered ? { rotate: 90 } : { rotate: 0 }}
                            >
                              <Arrow className="rounded-circle" />
                            </ArrowWrapper>
                            <Icon image={service?.icon} alt="" />
                          </BlockRounded>
                          {service?.title}
                        </ColNavLink>
                        <h2 className="font-weight-bold mt-1 mt-sm-3 mb-4 mb-sm-0 text-center">
                          {service?.title}
                        </h2>
                      </Col>
                    )}
                  </React.Fragment>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Services
