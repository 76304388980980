/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import Plaatjie from '@ubo/plaatjie'
import { Swiper, SwiperSlide } from 'swiper/react'

// Third party CSS
import 'swiper/css'
import 'swiper/css/pagination'

// Components
import ParseContent from 'components/shared/ParseContent'
import BlockRounded from 'components/elements/Blocks/BlockRounded'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import NavLink from 'components/shared/NavLink'

// Images
import Arrow from 'img/arrow-rounded.inline.svg'

// Hooks
import useService from 'hooks/useService'

// Interface
import { ServicesProps } from 'components/flex/Services/ServicesShell'

const Section = styled.section`
  color: ${({ theme }) => theme.color.primary};
  padding-bottom: 6rem;
`

const Icon = styled(Plaatjie)<{ highlighted?: boolean }>`
  ${(props) =>
    props.highlighted
      ? css`
          @media (min-width: 576px) {
            width: 210px;
            height: 190px;
          }

          @media (max-width: 575px) {
            width: 105px;
            height: 95px;
          }
        `
      : css`
          @media (min-width: 1440px) {
            width: 150px;
            height: 150px;
          }

          @media (max-width: 1439px) {
            width: 75px;
            height: 75px;
          }
        `}
`

const Col = styled(motion.div)`
  & h2 {
    @media (min-width: 1440px) {
      font-size: ${({ theme }) => theme.font.size.large};
    }
    @media (max-width: 1439px) {
      font-size: ${({ theme }) => theme.font.size.big};
    }
    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.medium};
    }
    @media (max-width: 575px) {
      font-size: ${({ theme }) => theme.font.size.small};
    }
  }
`

const Background = styled(Plaatjie)`
  z-index: -1;

  & img {
    object-position: top;
  }
`

const ArrowWrapper = styled(motion.div)`
  bottom: 0;
  right: 20px;

  @media (max-width: 767px) {
    & > svg {
      width: 40px;
      height: 40px;
    }
  }
`

const Content = styled(ParseContent)`
  @media (min-width: 992px) {
    & h2 {
      font-size: ${({ theme }) => theme.font.size.large};
    }
  }
`

interface MultiServiceProps {
  hoverIndex: Number
  // eslint-disable-next-line no-unused-vars
  setHoverIndex: (index: number) => void
  services: Array<any>
}

interface SingleServiceProps {
  name: string
  description: string
  service: any
  slug: string
}

const ServicesCase = ({ fields }: ServicesProps) => {
  const [hoverIndex, setHoverIndex] = useState(-1)

  const { services } = useService()

  return (
    <Section className="position-relative d-flex justify-content-center">
      <Background
        image={fields.background}
        className="position-absolute h-100"
        alt=""
      />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <ParseContent content={fields.description} className="my-5" />
            <div className="row justify-content-center">
              {services.length > 1 && (
                <MultiService
                  services={services}
                  hoverIndex={hoverIndex}
                  setHoverIndex={setHoverIndex}
                />
              )}
              {services.length < 2 && (
                <SingleService
                  name={services[0].name}
                  description={services[0].description}
                  service={services[0].service}
                  slug={services[0].slug}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

const SingleService: React.FC<SingleServiceProps> = ({
  name,
  description,
  service,
  slug,
}) => (
  <BlockRounded light className="px-sm-5 py-sm-4 mb-5">
    <div className="d-flex flex-column flex-md-row align-items-center py-3">
      <Icon highlighted image={service?.icon} alt="" className="mb-4 mb-md-0" />
      <div className="px-5">
        <Content content={description} className="mb-3" />
        <ButtonPrimary to={`/diensten/${slug}`}>Meer over {name}</ButtonPrimary>
      </div>
    </div>
  </BlockRounded>
)

const MultiService: React.FC<MultiServiceProps> = ({
  services,
  hoverIndex,
  setHoverIndex,
}) => (
  <Swiper
    breakpoints={{
      0: {
        slidesPerView: 1.5,
      },
      400: {
        slidesPerView: 2.5,
      },
      576: {
        slidesPerView: 3,
      },
    }}
  >
    {services?.map((service: SingleServiceProps, index) => {
      const hovered = hoverIndex === index

      return (
        <>
          <SwiperSlide key={index} className="overflow-visible pt-3">
            <NavLink to={`/diensten/${service.slug}`}>
              <Col
                className="d-flex flex-column justify-content-center align-items-center"
                onHoverStart={() => setHoverIndex(index)}
                onHoverEnd={() => setHoverIndex(-1)}
                role="button"
                tabIndex={-1}
              >
                <BlockRounded
                  light
                  className="position-relative h-100 d-flex justify-content-center align-items-center rounded-circle p-4 p-md-5 mb-2 mb-sm-0"
                >
                  <ArrowWrapper
                    className="position-absolute"
                    initial={{ rotate: 0 }}
                    animate={hovered ? { rotate: 90 } : { rotate: 0 }}
                  >
                    <Arrow className="rounded-circle" />
                  </ArrowWrapper>
                  <Icon image={service?.service.icon} alt="" />
                </BlockRounded>
                <h2 className="font-weight-bold mt-1 mt-sm-3 mb-4 mb-sm-0 text-center">
                  {service?.name}
                </h2>
              </Col>
            </NavLink>
          </SwiperSlide>
        </>
      )
    })}
  </Swiper>
)

export default ServicesCase
