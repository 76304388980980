import { useContext } from 'react'

import { ServiceContext, ContextProps } from 'templates/case'

const useService = () => {
  const context = useContext(ServiceContext)

  return context as ContextProps
}

export default useService
